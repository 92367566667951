































import { InlineLoader } from '@/shared';
import sink from '@/sink';
import { defineComponent, onMounted } from '@vue/composition-api';
import {
  PatientDetailsActivitiesFetched,
  PatientDetailsActivitiesTablePageUpdated,
  PatientDetailsActivitiesTableSorted,
} from './+state/events';

export default defineComponent({
  components: {
    InlineLoader,
  },
  setup() {
    onMounted(sink.lazyBroadcast(PatientDetailsActivitiesFetched()));

    const sortCol = sink.select('patient.details.activities.sort-col');
    const sortDir = sink.select('patient.details.activities.sort-dir');

    return {
      onChangeSortDirection: (isDesc: boolean) =>
        sink.broadcast(
          PatientDetailsActivitiesTableSorted({
            col: sortCol.value,
            dir: isDesc ? 'desc' : 'asc',
          }),
        ),
      updatePage: (page: number) =>
        sink.broadcast(PatientDetailsActivitiesTablePageUpdated({ page })),
      sortCol,
      sortDir,
      loading: sink.select('patient.details.activities.loading'),
      activities: sink.select('patient.details.activities.table'),
      count: sink.select('patient.details.activities.count'),
      pageNumber: sink.select('patient.details.activities.page-number'),
      tableMessage: sink.select('patient.details.activities.table-message'),
      headers: [
        { value: 'activityDate', text: 'Activity Date', sortable: true },
        { value: 'activity', text: 'Activity', sortable: false },
      ],
    };
  },
});
